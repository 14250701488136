import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/img/s-home.png';
import { useLayoutStore } from '@/store';
export default /*@__PURE__*/_defineComponent({
  __name: 'BreadCrumb',
  setup(__props) {
    const layoutStore = useLayoutStore();
    // 返回首页时，设置当前菜单为空
    const hadleClick = () => {
      layoutStore.setCurrentMenu(null);
    };
    return (_ctx, _cache) => {
      const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");
      const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");
      return _openBlock(), _createBlock(_component_el_breadcrumb, {
        class: "app-breadcrumb",
        separator: "/"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_breadcrumb_item, {
          to: {
            name: _unref(layoutStore).indexName
          },
          replace: true,
          onClick: hadleClick
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("div", {
            class: "breadcrumb-home"
          }, [_createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }), _createElementVNode("span", null, "首页")], -1)])),
          _: 1
        }, 8, ["to"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(layoutStore).currentMenuPath, item => {
          return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
            key: item.menuId
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(item.menuName), 1)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      });
    };
  }
});