import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";

import { ref } from 'vue';
const _hoisted_1 = {
  class: "title"
};
export default /*@__PURE__*/_defineComponent({
  __name: 'TagItem',
  props: {
    title: {},
    supportClose: {
      type: Boolean,
      default: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  emits: ["close"],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const emit = __emit;
    const root = ref();
    const icon = ref();
    const mouseEnter = ref(false);
    const iconEnter = ref(false);
    const onClose = () => {
      emit('close');
    };
    const mouseEnterHandle = () => {
      //iconEnter.value = false;
      mouseEnter.value = true;
    };
    // 解决鼠标移动过快mouseleave失效，图标不能正确改变样式
    const mouseMoveHandle = e => {
      if (['DIV', 'SPAN'].includes(e.target.nodeName)) {
        iconEnter.value = false;
      }
    };
    const mouseLeaveHandle = e => {
      iconEnter.value = false;
      //console.log(props.title, root.value?.contains(e.relatedTarget as Node), e);
      if (e.type == 'mouseout' && root.value?.contains(e.relatedTarget)) {
        return;
      }
      mouseEnter.value = false;
    };
    const iconMouseEnterHandle = () => {
      iconEnter.value = true;
    };
    const iconMouseLeaveHandle = () => {
      iconEnter.value = false;
    };
    __expose({
      root
    });
    return (_ctx, _cache) => {
      const _component_Close = _resolveComponent("Close");
      const _component_CircleCloseFilled = _resolveComponent("CircleCloseFilled");
      const _component_el_icon = _resolveComponent("el-icon");
      return _openBlock(), _createElementBlock("div", {
        ref_key: "root",
        ref: root,
        class: _normalizeClass(["tags-item", {
          active: _ctx.active
        }]),
        onMouseenter: _withModifiers(mouseEnterHandle, ["prevent"]),
        onMouseout: _withModifiers(mouseLeaveHandle, ["prevent"]),
        onMousemove: _withModifiers(mouseMoveHandle, ["prevent"])
      }, [_createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1), _ctx.supportClose && (_unref(mouseEnter) || _ctx.active) ? (_openBlock(), _createBlock(_component_el_icon, {
        key: 0,
        ref_key: "icon",
        ref: icon,
        style: {
          "margin-left": "6px",
          "color": "#999"
        },
        onMouseenter: _withModifiers(iconMouseEnterHandle, ["prevent"]),
        onMouseleave: _withModifiers(iconMouseLeaveHandle, ["prevent"]),
        onClick: _withModifiers(onClose, ["stop"])
      }, {
        default: _withCtx(() => [!_unref(iconEnter) ? (_openBlock(), _createBlock(_component_Close, {
          key: 0
        })) : _createCommentVNode("", true), _unref(iconEnter) ? (_openBlock(), _createBlock(_component_CircleCloseFilled, {
          key: 1,
          class: "hover-close"
        })) : _createCommentVNode("", true)]),
        _: 1
      }, 512)) : _createCommentVNode("", true)], 34);
    };
  }
});