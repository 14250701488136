import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useRoute } from 'vue-router';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import { useWindowResize } from '@/common/hooks/useWindowResize';

import { watch } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {
    const route = useRoute();
    watch(() => route.name, () => {
      //console.log('路由发生了变化', route.name, route.fullPath, route.path, route);
      document.title = process.env.VUE_APP_PROJECT_NAME;
      if (route.meta && route.meta.title) {
        document.title += ' - ' + route.meta.title;
      }
    });
    useWindowResize();
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_el_config_provider = _resolveComponent("el-config-provider");
      return _openBlock(), _createBlock(_component_el_config_provider, {
        locale: _unref(zhCn)
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view)]),
        _: 1
      }, 8, ["locale"]);
    };
  }
});