import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "form-single-fragment",
  style: {
    "position": "relative"
  }
};
import { ElMessage } from 'element-plus';
import { encrypt } from '@/common/utils';
import LoginController from '@/api/system/LoginController';

import { ref, reactive } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    dialog: {}
  },
  setup(__props) {
    const props = __props;
    const formModifyPassword = ref();
    const formData = reactive({
      oldPassword: undefined,
      password: undefined,
      repeatPassword: undefined
    });
    const rules = ref({
      oldPassword: [{
        required: true,
        message: '请输入旧密码',
        trigger: 'blur'
      }],
      password: [{
        required: true,
        message: '请输入新密码',
        trigger: 'blur'
      }],
      repeatPassword: [{
        required: true,
        message: '请输入新密码',
        trigger: 'blur'
      }]
    });
    const onCancel = () => {
      if (props.dialog) {
        props.dialog.cancel();
      }
    };
    const onSave = () => {
      formModifyPassword.value.validate(valid => {
        if (!valid) return;
        if (formData.password !== formData.repeatPassword) {
          ElMessage.error('两次密码输入不一致，请核对！');
          return;
        }
        let params = {
          oldPass: encrypt(formData.oldPassword),
          newPass: encrypt(formData.password)
        };
        LoginController.changePassword(params).then(res => {
          ElMessage.success('密码修改成功');
          if (props.dialog) {
            props.dialog.submit(res);
          }
        }).catch(e => {
          console.error(e);
        });
      });
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
        ref_key: "formModifyPassword",
        ref: formModifyPassword,
        model: _unref(formData),
        class: "full-width-input",
        rules: _unref(rules),
        style: {
          "width": "100%"
        },
        "label-width": "120px",
        size: _ctx.defaultFormItemSize,
        "label-position": "right",
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
      }, {
        default: _withCtx(() => [_createVNode(_component_el_row, {
          gutter: 20
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 24
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "旧密码",
              prop: "oldPassword"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                class: "input-item",
                modelValue: _unref(formData).oldPassword,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(formData).oldPassword = $event),
                modelModifiers: {
                  trim: true
                },
                type: "password",
                "show-password": "",
                clearable: true,
                placeholder: "旧密码"
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 24
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "新密码",
              prop: "password"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                class: "input-item",
                modelValue: _unref(formData).password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(formData).password = $event),
                modelModifiers: {
                  trim: true
                },
                type: "password",
                "show-password": "",
                clearable: true,
                placeholder: "新密码"
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 24
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "新密码确认",
              prop: "repeatPassword"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                class: "input-item",
                modelValue: _unref(formData).repeatPassword,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(formData).repeatPassword = $event),
                modelModifiers: {
                  trim: true
                },
                type: "password",
                "show-password": "",
                clearable: true,
                placeholder: "新密码确认"
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 24
          }, {
            default: _withCtx(() => [_createVNode(_component_el_row, {
              class: "no-scroll flex-box",
              type: "flex",
              justify: "end"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_button, {
                size: _ctx.defaultFormItemSize,
                plain: true,
                onClick: _cache[3] || (_cache[3] = $event => onCancel(false))
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode(" 取消 ")])),
                _: 1
              }, 8, ["size"]), _createVNode(_component_el_button, {
                type: "primary",
                size: _ctx.defaultFormItemSize,
                onClick: _cache[4] || (_cache[4] = $event => onSave())
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode(" 保存 ")])),
                _: 1
              }, 8, ["size"])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model", "rules", "size"])]);
    };
  }
});