import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "main-panel"
};
import { useLayoutStore } from '@/store';
import TagItem from './TagItem.vue';

import { ref, onMounted, nextTick, watch } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'TagPanel',
  setup(__props) {
    const layoutStore = useLayoutStore();
    const panel = ref();
    const scroll = ref();
    const home = ref();
    const items = ref([]);
    const contextMenuVisible = ref(false);
    const translateX = ref(0);
    const left = ref(0);
    const top = ref(0);
    let currentMenu = null;
    const onTagItemClick = item => {
      layoutStore.setCurrentMenu(item);
    };
    const openMenu = (item, event) => {
      // console.log(item, event);
      currentMenu = item;
      contextMenuVisible.value = true;
      left.value = event.clientX;
      top.value = event.clientY;
    };
    const onMenuMaskClick = () => {
      console.log('onMenuMaskClick');
      contextMenuVisible.value = false;
    };
    const openMaskMenu = e => {
      // console.log('openMaskMenu');
      e.preventDefault();
    };
    const closeSelectTag = () => {
      if (currentMenu != null) {
        layoutStore.removeTag(currentMenu.menuId);
      }
    };
    const closeOthersTags = () => {
      //console.log('closeOthersTags');
      if (currentMenu != null) {
        layoutStore.closeOtherTags(currentMenu.menuId);
      } else {
        layoutStore.clearAllTags();
      }
    };
    const onTagItemClose = item => {
      layoutStore.removeTag(item.menuId);
    };
    onMounted(() => {
      initTagPanel(layoutStore.tagList);
      scrollToMenu(layoutStore.currentMenu);
    });
    function initTagPanel(tagList) {
      nextTick(() => {
        let width = (home.value ? home.value.root.offsetWidth : 0) + 60 + tagList.length * 5;
        //console.log('width', width);
        items.value.forEach(row => {
          //console.log(row);
          width += row.root.offsetWidth + 5;
        });
        //console.log('width', width);
        scroll.value.style.width = width + 'px';
        const showArrow = width > panel.value.offsetWidth;
        if (!showArrow) {
          translateX.value = 0;
        } else if (panel.value.offsetWidth - width >= translateX.value && translateX.value !== 0) {
          translateX.value = panel.value.offsetWidth - width;
        }
        //console.log('xxx', translateX.value);
      });
    }
    function scrollToMenu(menu) {
      nextTick(() => {
        layoutStore.tagList.forEach((row, index) => {
          if (row.menuId === menu.menuId) {
            let el = items.value[index].root;
            if (-el.offsetLeft > translateX.value) {
              translateX.value = -el.offsetLeft;
            } else if (el.offsetLeft + el.offsetWidth + 60 > panel.value.offsetWidth - translateX.value) {
              translateX.value = panel.value.offsetWidth - el.offsetLeft - el.offsetWidth - 60;
            }
          }
        });
      });
    }
    watch(() => layoutStore.currentMenu, menu => {
      scrollToMenu(menu);
    });
    watch(() => layoutStore.tagList, tagList => {
      if (tagList && tagList.length) {
        initTagPanel(tagList);
      }
    }, {
      deep: true
    });
    const leftClick = () => {
      if (!items.value) return;
      let x = 0;
      for (let i = layoutStore.tagList.length - 1; i >= 0; i--) {
        const el = items.value[i].root;
        //console.log(el.innerText, layoutStore.tagList[i].menuName);
        console.log(el.offsetLeft, translateX.value, -el.offsetLeft > translateX.value);
        if (-el.offsetLeft > translateX.value) {
          x = -el.offsetLeft;
          break;
        }
        console.log('x', x);
      }
      if (x > 0) {
        x = 0;
      }
      console.log('x', x);
      translateX.value = x;
    };
    const rightClick = () => {
      if (!items.value) return;
      let x = translateX.value;
      for (let i = 0; i < layoutStore.tagList.length; i++) {
        const el = items.value[i].root;
        if (el.offsetLeft + el.offsetWidth + 60 > panel.value.offsetWidth - translateX.value) {
          x = panel.value.offsetWidth - el.offsetLeft - el.offsetWidth - 60;
          break;
        }
      }
      console.log('x', x, panel.value.offsetWidth, scroll.value.offsetWidth);
      let max = panel.value.offsetWidth - scroll.value.offsetWidth - 60;
      console.log('max', max);
      if (x < max) {
        x = max;
      }
      if (x > 0) x = 0;
      translateX.value = x;
    };
    return (_ctx, _cache) => {
      const _component_el_icon_arrow_left = _resolveComponent("el-icon-arrow-left");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_icon_arrow_right = _resolveComponent("el-icon-arrow-right");
      return _openBlock(), _createElementBlock("div", {
        class: "tags-panel",
        ref_key: "panel",
        ref: panel
      }, [_createVNode(_component_el_icon, {
        class: "arrow left"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_icon_arrow_left, {
          onClick: leftClick
        })]),
        _: 1
      }), _createVNode(_component_el_icon, {
        class: "arrow right"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_icon_arrow_right, {
          onClick: rightClick
        })]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
        class: "scroll-box",
        ref_key: "scroll",
        ref: scroll,
        style: _normalizeStyle({
          transform: 'translateX(' + _unref(translateX) + 'px)'
        })
      }, [_createVNode(TagItem, {
        ref_key: "home",
        ref: home,
        class: "item",
        title: "主页",
        active: _unref(layoutStore).currentMenuId == null,
        supportClose: false,
        onClick: _cache[0] || (_cache[0] = $event => onTagItemClick(null)),
        onContextmenu: _cache[1] || (_cache[1] = _withModifiers($event => openMenu(null, $event), ["prevent"]))
      }, null, 8, ["active"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(layoutStore).tagList, item => {
        return _openBlock(), _createBlock(TagItem, {
          ref_for: true,
          ref_key: "items",
          ref: items,
          class: "item",
          key: item.menuId,
          title: item.menuName,
          active: item.menuId == _unref(layoutStore).currentMenuId,
          onClose: $event => onTagItemClose(item),
          onClick: $event => onTagItemClick(item),
          onContextmenu: _withModifiers($event => openMenu(item, $event), ["prevent"])
        }, null, 8, ["title", "active", "onClose", "onClick", "onContextmenu"]);
      }), 128))], 4)]), _withDirectives(_createElementVNode("div", {
        onClick: _withModifiers(onMenuMaskClick, ["stop"]),
        onContextmenu: openMaskMenu,
        style: {
          "position": "fixed",
          "top": "0",
          "left": "0",
          "z-index": "99999",
          "width": "100vw",
          "height": "100vh",
          "background": "rgb(0 0 0 / 1%)"
        }
      }, [_createElementVNode("ul", {
        class: "contextmenu",
        style: _normalizeStyle([{
          "z-index": "99999",
          "background": "white"
        }, {
          left: _unref(left) + 'px',
          top: _unref(top) + 'px'
        }])
      }, [_createElementVNode("li", {
        onClick: closeSelectTag,
        class: _normalizeClass(_unref(currentMenu) == null ? 'disabled' : '')
      }, "关闭", 2), _createElementVNode("li", {
        onClick: closeOthersTags
      }, "关闭其他")], 4)], 544), [[_vShow, _unref(contextMenuVisible)]])], 512);
    };
  }
});