import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  class: "form-single-fragment",
  style: {
    "position": "relative"
  }
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  key: 1,
  class: "el-icon-plus upload-image-item"
};
import { ElMessage } from 'element-plus';
import { useUpload } from '@/common/hooks/useUpload';
import { useLoginStore } from '@/store';
import LoginController from '@/api/system/LoginController';

import { computed } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {
    const {
      getUploadFileUrl,
      getUploadActionUrl,
      getUploadHeaders
    } = useUpload();
    const loginStore = useLoginStore();
    const userInfo = loginStore.userInfo;
    const onHeadImageUploadSuccess = (response, file, fileList) => {
      if (response.success) {
        loginStore.setHeadImage(response.data);
      } else {
        ElMessage.error(response.message);
      }
    };
    const onUploadError = (e, file, fileList) => {
      ElMessage.error('文件上传失败');
    };
    const onUploadLimit = (files, fileList) => {
      ElMessage.error('已经超出最大上传个数限制');
    };
    const getHeadImageUrl = () => {
      if (userInfo && userInfo.headImageUrl != null && userInfo.headImageUrl !== '') {
        let temp = getUploadFileUrl(userInfo.headImageUrl, {
          filename: userInfo.headImageUrl.filename
        });
        return temp;
      } else {
        return null;
      }
    };
    const headImageUploadUrl = computed(() => {
      return getUploadActionUrl(LoginController.changeHeadImageUrl());
    });
    return (_ctx, _cache) => {
      const _component_el_upload = _resolveComponent("el-upload");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
        ref: "formModifyPassword",
        class: "full-width-input",
        style: {
          "width": "100%"
        },
        "label-width": "80px",
        size: _ctx.defaultFormItemSize,
        "label-position": "right",
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
      }, {
        default: _withCtx(() => [_createVNode(_component_el_row, {
          gutter: 20
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 24
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "用户头像"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_upload, {
                class: "upload-image-item",
                name: "uploadFile",
                headers: _unref(getUploadHeaders),
                action: _unref(headImageUploadUrl),
                "show-file-list": false,
                accept: ".jpg,.png,.jpeg",
                "on-success": onHeadImageUploadSuccess,
                "on-error": onUploadError,
                "on-exceed": onUploadLimit
              }, {
                default: _withCtx(() => [getHeadImageUrl() ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "upload-image-show",
                  src: getHeadImageUrl()
                }, null, 8, _hoisted_2)) : (_openBlock(), _createElementBlock("i", _hoisted_3))]),
                _: 1
              }, 8, ["headers", "action"])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["size"])]);
    };
  }
});