import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "multi-column-wrap"
};
const _hoisted_2 = {
  class: "multi-column-list"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = ["title"];
const _hoisted_5 = {
  style: {
    "padding": "24px 0"
  }
};
import { useLayoutStore } from '@/store';
import OrangeIcon from '@/components/icons/index.vue';
import multiColumnMenu from './multi-column-menu.vue';

import { ref, watch } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'multi-column',
  props: {
    menuList: {}
  },
  setup(__props) {
    const popover = ref([]);
    const children = ref([]);
    const layoutStore = useLayoutStore();
    const onColumnChange = column => {
      layoutStore.setCurrentColumn(column);
    };
    const selectMenu = index => {
      // console.log('selectMenu', index);
      // 自动隐藏弹出体，只在侧边栏折叠状态下才会触发
      popover.value[index].hide();
    };
    watch(() => layoutStore.currentColumn, (newVal, oldVal) => {
      if (newVal == oldVal) return;
      children.value = newVal.children || [];
    }, {
      immediate: true
    });
    return (_ctx, _cache) => {
      const _component_el_popover = _resolveComponent("el-popover");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.menuList && _ctx.menuList.length ? (_openBlock(), _createBlock(_component_el_scrollbar, {
        key: 0,
        "wrap-class": "scrollbar_dropdown__wrap",
        style: {
          "width": "80px",
          "height": "calc(100vh - 60px)"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (menu, index) => {
          return _openBlock(), _createBlock(_component_el_popover, {
            key: menu.menuId,
            ref_for: true,
            ref_key: "popover",
            ref: popover,
            placement: "right-start",
            width: "220",
            trigger: "hover",
            disabled: !menu.children || (menu.children || []).length === 0 || !_unref(layoutStore).collapsed,
            "show-arrow": false
          }, {
            reference: _withCtx(() => [_createElementVNode("li", {
              onClick: $event => onColumnChange(menu),
              class: _normalizeClass({
                active: _unref(layoutStore).currentColumnId === menu.menuId
              })
            }, [menu.icon ? (_openBlock(), _createBlock(OrangeIcon, {
              key: 0,
              icon: menu.icon
            }, null, 8, ["icon"])) : _createCommentVNode("", true), _createElementVNode("p", {
              title: menu.menuName.length > 4 ? menu.menuName : undefined
            }, _toDisplayString(menu.menuName), 9, _hoisted_4)], 10, _hoisted_3)]),
            default: _withCtx(() => [(menu.children || []).length ? (_openBlock(), _createBlock(multiColumnMenu, {
              key: 0,
              menuList: menu.children,
              level: 1,
              onSelect: $event => selectMenu(index),
              column: menu
            }, null, 8, ["menuList", "onSelect", "column"])) : _createCommentVNode("", true)]),
            _: 2
          }, 1032, ["disabled"]);
        }), 128))])]),
        _: 1
      })) : _createCommentVNode("", true), _unref(children) && _unref(children).length ? (_openBlock(), _createBlock(_component_el_scrollbar, {
        key: 1,
        class: "children-menu-scrollbar",
        "wrap-class": "scrollbar_dropdown__wrap",
        style: {
          "height": "calc(100vh - 60px)",
          "background-color": "white"
        },
        "scroll-x": false
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [(_openBlock(), _createBlock(multiColumnMenu, {
          menuList: _unref(children),
          level: 0,
          key: _unref(layoutStore).currentColumnId,
          columnId: _unref(layoutStore).currentColumnId
        }, null, 8, ["menuList", "columnId"]))])]),
        _: 1
      })) : _createCommentVNode("", true)]);
    };
  }
});