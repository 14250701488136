import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "multi-column-menu"
};
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  class: "menu-name"
};
const _hoisted_4 = {
  class: "popover-box"
};
import { ArrowRight as ElIconArrowRight } from '@element-plus/icons-vue';
import { useLayoutStore } from '@/store';
import OrangeIcon from '@/components/icons/index.vue';
import { SysMenuType } from '@/common/staticDict';
import { useSelectMenu } from './hooks';

import { nextTick } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'multi-column-menu',
  props: {
    level: {
      default: 0
    },
    menuList: {},
    column: {}
  },
  emits: ["select"],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const props = __props;
    const layoutStore = useLayoutStore();
    const {
      selectMenu
    } = useSelectMenu();
    const selectMenuItem = menu => {
      if (layoutStore.currentMenuId == menu.menuId || menu.menuType == SysMenuType.DIRECTORY) return;
      // 单页面清空所有tags和cachePage
      // if (!layoutStore.supportTags) {
      //   layoutStore.clearAllTags();
      // }
      if (props.column && props.column.menuId !== layoutStore.currentColumnId) {
        layoutStore.setCurrentColumn(props.column);
      }
      nextTick(() => {
        selectMenu(menu);
        select();
      });
    };
    const select = () => {
      emit('select');
    };
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_multiColumnMenu = _resolveComponent("multiColumnMenu", true);
      const _component_el_popover = _resolveComponent("el-popover");
      return _openBlock(), _createElementBlock("ul", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, menu => {
        return _openBlock(), _createBlock(_component_el_popover, {
          key: menu.menuId,
          placement: "right-start",
          width: "220",
          trigger: "hover",
          disabled: !menu.children || (menu.children || []).length === 0 || _ctx.level >= 1,
          "show-arrow": false
        }, {
          reference: _withCtx(() => [_createElementVNode("li", {
            onClick: $event => selectMenuItem(menu),
            class: _normalizeClass({
              active: _unref(layoutStore).currentMenuId === menu.menuId
            })
          }, [_createElementVNode("div", _hoisted_3, [menu.icon ? (_openBlock(), _createBlock(OrangeIcon, {
            key: 0,
            icon: menu.icon,
            style: {
              "margin-right": "5px",
              "font-size": "18px"
            }
          }, null, 8, ["icon"])) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString(menu.menuName), 1)]), menu.children && menu.children.length ? (_openBlock(), _createBlock(_component_el_icon, {
            key: 0
          }, {
            default: _withCtx(() => [_createVNode(_unref(ElIconArrowRight))]),
            _: 1
          })) : _createCommentVNode("", true), _ctx.level >= 1 && (menu.children || []).length ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["multi-column-menu-popover", {
              level2: _ctx.level > 1
            }])
          }, [_createElementVNode("div", _hoisted_4, [(_openBlock(), _createBlock(_component_multiColumnMenu, {
            menuList: menu.children,
            key: _ctx.column?.menuId + '-' + menu.menuId,
            level: 2,
            column: _ctx.column,
            onSelect: select
          }, null, 8, ["menuList", "column"]))])], 2)) : _createCommentVNode("", true)], 10, _hoisted_2)]),
          default: _withCtx(() => [(menu.children || []).length && _ctx.level < 1 ? (_openBlock(), _createBlock(_component_multiColumnMenu, {
            key: 0,
            menuList: menu.children,
            level: _ctx.level + 1,
            column: _ctx.column,
            onSelect: select
          }, null, 8, ["menuList", "level", "column"])) : _createCommentVNode("", true)]),
          _: 2
        }, 1032, ["disabled"]);
      }), 128))]);
    };
  }
});