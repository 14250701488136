import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/img/login_logo2.png';
import _imports_1 from '@/assets/img/login_title.png';
import _imports_2 from '@/assets/img/orange.png';
const _hoisted_1 = {
  class: "login-form"
};
const _hoisted_2 = {
  class: "login-box"
};
const _hoisted_3 = {
  class: "login-input"
};
const _hoisted_4 = {
  style: {
    "display": "flex",
    "justify-content": "center",
    "align-items": "center",
    "cursor": "pointer",
    "height": "100%",
    "margin-right": "10px"
  }
};
const _hoisted_5 = ["src"];
import { useRouter } from 'vue-router';
import { encrypt, setToken, treeDataTranslate } from '@/common/utils/index';
import { useLoginStore, useLayoutStore } from '@/store';
import LoginController from '@/api/system/LoginController';
import eyeOpen from '@/assets/img/eye_open.png';
import eyeClose from '@/assets/img/eye_close.png';

import { onMounted, ref, reactive } from 'vue';
const bkImg = 'https://orangeforms-website.oss-cn-beijing.aliyuncs.com/login_icon2.png';
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {
    onMounted(() => {
      layoutStore.setMenuList([]);
      loginStore.setUserInfo({});
      setToken(null);
    });
    const loginForm = ref();
    const loginStore = useLoginStore();
    const layoutStore = useLayoutStore();
    const router = useRouter();
    const showPassword = ref(false);
    const dataForm = reactive({
      mobilePhone: 'admin',
      password: '123456'
    });
    const dataRule = {
      mobilePhone: [{
        required: true,
        message: '帐号不能为空',
        trigger: 'blur'
      }],
      password: [{
        required: true,
        message: '密码不能为空',
        trigger: 'blur'
      }]
    };
    const dataFormSubmit = () => {
      loginForm.value?.validate(valid => {
        if (valid) {
          login();
        }
      });
    };
    const login = function (verifyParams = null) {
      let params = {
        loginName: dataForm.mobilePhone,
        password: encrypt(dataForm.password),
        captchaVerification: verifyParams?.captchaVerification
      };
      setToken(null);
      LoginController.login(params).then(data => {
        //console.log('login >>>', data);
        if (data.data.menuList) {
          layoutStore.setMenuList(treeDataTranslate(data.data.menuList, 'menuId', 'parentId'));
          delete data.data.menuList;
        }
        setToken(data.data.tokenData);
        layoutStore.setCurrentMenu(null);
        layoutStore.clearAllTags();
        if (data.data.tokenData) {
          delete data.data.tokenData;
        }
        loginStore.setUserInfo(data.data);
        // 登录成功跳转页面
        router.replace({
          name: 'main'
        });
      }).catch(e => {
        console.error(e);
      });
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[10] || (_cache[10] = _createStaticVNode("<div class=\"logo-box logo-right\"><img src=\"" + _imports_0 + "\" style=\"height:40px;\" alt=\"\"><div class=\"el-space\" style=\"align-items:center;gap:8px;\"><a href=\"https://www.orangeforms.com/\" target=\"_blank\">橙单官网</a><span style=\"color:#999 !important;\">|</span><a href=\"https://code.orangeforms.com/\" target=\"_blank\">代码生成</a></div></div>", 1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: "img-box"
      }, [_cache[5] || (_cache[5] = _createElementVNode("img", {
        class: "img-title",
        style: {
          "height": "55px"
        },
        src: _imports_1,
        alt: ""
      }, null, -1)), _createElementVNode("img", {
        src: bkImg,
        style: {
          "flex-shrink": "0",
          "height": "100%"
        }
      })]), _createElementVNode("div", _hoisted_3, [_cache[7] || (_cache[7] = _createElementVNode("img", {
        src: _imports_2,
        style: {
          "width": "62px",
          "margin-bottom": "9px"
        },
        alt: ""
      }, null, -1)), _cache[8] || (_cache[8] = _createElementVNode("span", {
        class: "title"
      }, "欢迎登录", -1)), _cache[9] || (_cache[9] = _createElementVNode("span", {
        class: "desc"
      }, "橙单代码生成演示工程", -1)), _createVNode(_component_el_form, {
        model: _unref(dataForm),
        rules: dataRule,
        "label-position": "top",
        ref_key: "loginForm",
        ref: loginForm,
        onKeyup: _withKeys(_withModifiers(() => {}, ["stop"]), ["enter"])
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            prop: "mobilePhone",
            label: "",
            style: {
              "margin-top": "48px",
              "margin-bottom": "25px"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(dataForm).mobilePhone,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(dataForm).mobilePhone = $event),
              style: {
                "width": "100%",
                "height": "50px"
              },
              placeholder: "请输入帐号"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            prop: "password",
            label: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(dataForm).password,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(dataForm).password = $event),
              style: {
                "width": "100%",
                "height": "50px"
              },
              type: _unref(showPassword) ? 'text' : 'password',
              placeholder: "请输入密码",
              onKeyup: _cache[3] || (_cache[3] = _withKeys($event => dataFormSubmit(), ["enter"]))
            }, {
              suffix: _withCtx(() => [_createElementVNode("i", _hoisted_4, [_createElementVNode("img", {
                src: !_unref(showPassword) ? _unref(eyeClose) : _unref(eyeOpen),
                style: {
                  "width": "24px"
                },
                onClick: _cache[1] || (_cache[1] = $event => showPassword.value = !_unref(showPassword))
              }, null, 8, _hoisted_5)])]),
              _: 1
            }, 8, ["modelValue", "type"])]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_button, {
          class: "login-btn-submit",
          type: "primary",
          size: "large",
          onClick: _cache[4] || (_cache[4] = $event => dataFormSubmit()),
          style: {
            "width": "100%",
            "height": "50px",
            "margin-top": "32px",
            "font-size": "16px"
          }
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode(" 登录 ")])),
          _: 1
        })]),
        _: 1
      }, 8, ["model", "onKeyup"])])]), _cache[11] || (_cache[11] = _createElementVNode("div", {
        style: {
          "position": "absolute",
          "right": "0",
          "bottom": "0",
          "left": "0",
          "height": "44px",
          "font-size": "14px",
          "color": "#666",
          "background-color": "#f7fafe"
        }
      }, [_createElementVNode("p", {
        style: {
          "padding": "0",
          "margin": "0",
          "text-align": "center",
          "line-height": "44px"
        }
      }, " Copyright © 2024 知视科技 津 ICP 备 20006136 号-1 津公网安备 12011602000454 号 ")], -1))]);
    };
  }
});