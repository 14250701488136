import "core-js/modules/es.array.push.js";
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, normalizeStyle as _normalizeStyle } from "vue";
import _imports_0 from '@/assets/img/logo_white.png';
import _imports_1 from '@/assets/img/login_logo.png';
const _hoisted_1 = {
  key: 0,
  class: "logo has-multi-column"
};
const _hoisted_2 = {
  class: "header-main"
};
const _hoisted_3 = {
  key: 0,
  class: "logo",
  style: {
    "padding-left": "8px",
    "margin-right": "8px"
  }
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  class: "header-menu",
  style: {
    "flex-grow": "1"
  }
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "el-dropdown-link"
};
const _hoisted_8 = {
  class: "layout-content"
};
const _hoisted_9 = {
  key: 0,
  class: "tag-wrap"
};
import { ElMessage, ElMessageBox } from 'element-plus';
import { useRouter, useRoute } from 'vue-router';
import defaultHeaderImg from '@/assets/img/default-header.jpg';
import { getToken, setToken } from '@/common/utils/index';
import { useLayoutStore, useLoginStore, useMessage } from '@/store';
import { SysMenuBindType, SysOnlineFormType } from '@/common/staticDict';
import LoginController from '@/api/system/LoginController';
import { useUpload } from '@/common/hooks/useUpload';
import { useCommon } from '@/common/hooks/useCommon';
import Sidebar from './components/Sidebar.vue';
import BreadCrumb from './components/BreadCrumb.vue';
import TagPanel from './components/TagPanel.vue';
import FormModifyPassword from './components/formModifyPassword/index.vue';
import FormModifyHeadImage from './components/formModifyHeadImage/index.vue';

import { inject, ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
const __default__ = {
  name: 'Layout'
};
export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {
    const {
      Dialog
    } = useCommon();
    const router = useRouter();
    const route = useRoute();
    const layoutStore = useLayoutStore();
    const loginStore = useLoginStore();
    const documentClientHeight = inject('documentClientHeight', ref(500));
    const projectName = process.env.VUE_APP_PROJECT_NAME;
    const {
      getUploadFileUrl
    } = useUpload();
    const userInfo = loginStore.userInfo;
    // TODO 切换菜单会触发该方法？
    // 用户头像
    const headerImg = computed(() => {
      if (userInfo && userInfo.headImageUrl) {
        let temp = getUploadFileUrl(userInfo.headImageUrl, {
          filename: userInfo.headImageUrl.filename
        });
        return temp;
      } else {
        return null;
      }
    });
    // 消息数量
    const messageStore = useMessage();
    const messageCount = computed(() => {
      return messageStore.messageCount || {};
    });
    // TODO 监听当前菜单变化，跳转到目标路由
    watch(() => layoutStore.currentMenu, (newVal, oldVal) => {
      //console.log('当前页发生变化', newVal, oldVal);
      if (newVal != oldVal) {
        jumpTo(newVal);
      }
      // else {
      //   if (route.name != layoutStore.indexName) {
      //     router.replace({
      //       name: layoutStore.indexName,
      //     });
      //   }
      // }
    });
    // 路由跳转
    function jumpTo(menuItem) {
      if (menuItem != null) {
        // 路由菜单
        if (menuItem.bindType === SysMenuBindType.ROUTER && menuItem.formRouterName != null && menuItem.formRouterName !== '') {
          router.replace({
            name: menuItem.formRouterName
          });
          return;
        }
        // 在线表单菜单
        if (menuItem.bindType === SysMenuBindType.ONLINE_FORM && menuItem.onlineFormId != null && menuItem.onlineFormId !== '') {
          router.replace({
            name: 'onlineForm',
            query: {
              formId: menuItem.onlineFormId,
              formType: SysOnlineFormType.QUERY
            }
          });
          return;
        }
        // 工单列表菜单
        if (menuItem.bindType === SysMenuBindType.WORK_ORDER && menuItem.onlineFormId != null && menuItem.onlineFormId !== '' && menuItem.onlineFlowEntryId != null && menuItem.onlineFlowEntryId !== '') {
          router.replace({
            name: 'onlineForm',
            query: {
              formId: menuItem.onlineFormId,
              entryId: menuItem.onlineFlowEntryId,
              formType: SysOnlineFormType.WORK_ORDER
            }
          });
          return;
        }
        // 报表菜单
        if (menuItem.bindType === SysMenuBindType.REPORT && menuItem.reportPageId != null && menuItem.reportPageId !== '') {
          router.replace({
            name: 'reportRender',
            query: {
              pageId: menuItem.reportPageId
            }
          });
          return;
        }
        // 外部链接
        if (menuItem.bindType === SysMenuBindType.THRID_URL && menuItem.targetUrl != null && menuItem.targetUrl !== '') {
          let token = getToken();
          let targetUrl = menuItem.targetUrl;
          if (targetUrl.indexOf('?') === -1) {
            targetUrl = targetUrl + '?';
          }
          targetUrl = targetUrl + 'token=' + token;
          window.open(targetUrl);
          return;
        }
      }
      console.warn('没有匹配到目标路由');
      if (route.name !== layoutStore.indexName) {
        router.replace({
          name: layoutStore.indexName
        });
      }
    }
    const getMainStyle = computed(() => {
      return [{
        height: documentClientHeight.value + 'px'
      }];
    });
    const getContextStyle = computed(() => {
      return [{
        height: documentClientHeight.value - (layoutStore.supportTags ? 108 : 60) + 'px'
      }];
    });
    const getRouterViewStyle = computed(() => {
      return [{
        'min-height': documentClientHeight.value - (layoutStore.supportTags ? 108 : 60) - 32 + 'px'
      }];
    });
    const handleMessage = command => {
      router.push({
        name: 'formMessage',
        query: {
          type: command
        }
      });
    };
    const handleCommand = command => {
      switch (command) {
        case 'logout':
          ElMessageBox.confirm('是否退出登录？', '', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            LoginController.logout().then(() => {
              ElMessage({
                type: 'success',
                message: '退出成功'
              });
              setToken(null);
              router.replace('/login');
            }).catch(e => {
              console.log(e);
              ElMessage({
                type: 'error',
                message: e
              });
            });
          }).catch(() => {
            console.log('取消退出');
          });
          break;
        case 'modifyPassword':
          Dialog.show('修改密码', FormModifyPassword, {
            area: '500px'
          }, {}).catch(e => {
            console.warn(e);
          });
          break;
        case 'modifyHeadImage':
          Dialog.show('修改头像', FormModifyHeadImage, {
            area: '500px'
          }, {});
          break;
        default:
          ElMessage.warning(`click on item `);
          break;
      }
    };
    onMounted(() => {
      messageStore.startMessage();
    });
    onBeforeUnmount(() => {
      messageStore.stopMessage();
    });
    return (_ctx, _cache) => {
      const _component_el_badge = _resolveComponent("el-badge");
      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      const _component_el_icon_arrow_down = _resolveComponent("el-icon-arrow-down");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_header = _resolveComponent("el-header");
      const _component_el_aside = _resolveComponent("el-aside");
      const _component_router_view = _resolveComponent("router-view");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      const _component_el_main = _resolveComponent("el-main");
      const _component_el_container = _resolveComponent("el-container");
      return _openBlock(), _createBlock(_component_el_container, {
        class: _normalizeClass('container-' + _ctx.defaultFormItemSize),
        style: _normalizeStyle(_unref(getMainStyle))
      }, {
        default: _withCtx(() => [_createVNode(_component_el_container, {
          style: {
            "background-color": "#f5f8f9"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_header, {
            class: _normalizeClass(["header", _unref(layoutStore).supportColumn ? 'multi-column-header' : '']),
            style: {
              "padding": "0"
            }
          }, {
            default: _withCtx(() => [_unref(layoutStore).supportColumn ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[1] || (_cache[1] = [_createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }, null, -1)]))) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [!_unref(layoutStore).supportColumn ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[2] || (_cache[2] = [_createElementVNode("img", {
              src: _imports_1,
              alt: ""
            }, null, -1)]))) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(projectName)), 1), _createVNode(BreadCrumb, {
              class: "breadcrumb-container"
            }), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_dropdown, {
              trigger: "click",
              style: {
                "margin-right": "14px"
              },
              onCommand: handleMessage
            }, {
              dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, {
                style: {
                  "min-width": "130px"
                }
              }, {
                default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
                  class: "user-dropdown-item",
                  command: "remindingMessage"
                }, {
                  default: _withCtx(() => [_cache[4] || (_cache[4] = _createTextVNode(" 催办消息 ")), _createVNode(_component_el_badge, {
                    value: _unref(messageCount).remindingMessageCount,
                    hidden: !_unref(messageCount).remindingMessageCount || _unref(messageCount).remindingMessageCount <= 0
                  }, null, 8, ["value", "hidden"])]),
                  _: 1
                }), _createVNode(_component_el_dropdown_item, {
                  class: "user-dropdown-item",
                  command: "copyMessage"
                }, {
                  default: _withCtx(() => [_cache[5] || (_cache[5] = _createTextVNode(" 抄送消息 ")), _createVNode(_component_el_badge, {
                    value: _unref(messageCount).copyMessageCount,
                    hidden: !_unref(messageCount).copyMessageCount || _unref(messageCount).copyMessageCount <= 0
                  }, null, 8, ["value", "hidden"])]),
                  _: 1
                })]),
                _: 1
              })]),
              default: _withCtx(() => [_createVNode(_component_el_badge, {
                "is-dot": "",
                hidden: !_unref(messageCount).totalCount || _unref(messageCount).totalCount <= 0,
                style: {
                  "height": "20px",
                  "line-height": "20px",
                  "cursor": "pointer"
                }
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("i", {
                  class: "online-icon icon-message",
                  style: {
                    "font-size": "16px",
                    "color": "#333"
                  }
                }, null, -1)])),
                _: 1
              }, 8, ["hidden"])]),
              _: 1
            }), _cache[9] || (_cache[9] = _createElementVNode("span", {
              class: "line"
            }, null, -1)), _createElementVNode("img", {
              src: _unref(headerImg) ? _unref(headerImg) : _unref(defaultHeaderImg),
              class: "header-img"
            }, null, 8, _hoisted_6), _createVNode(_component_el_dropdown, {
              class: "user-dropdown",
              trigger: "click",
              onCommand: handleCommand
            }, {
              dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
                default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
                  class: "user-dropdown-item",
                  command: "modifyPassword"
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("修改密码")])),
                  _: 1
                }), _createVNode(_component_el_dropdown_item, {
                  class: "user-dropdown-item",
                  command: "modifyHeadImage"
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("修改头像")])),
                  _: 1
                }), _createVNode(_component_el_dropdown_item, {
                  class: "user-dropdown-item",
                  command: "logout"
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("退出登录")])),
                  _: 1
                })]),
                _: 1
              })]),
              default: _withCtx(() => [_createElementVNode("span", _hoisted_7, [_createTextVNode(_toDisplayString((_unref(userInfo) || {}).showName), 1), _createVNode(_component_el_icon, {
                class: "el-icon--right"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_icon_arrow_down)]),
                _: 1
              })])]),
              _: 1
            })])])]),
            _: 1
          }, 8, ["class"]), _createVNode(_component_el_main, {
            class: "layout-main"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_aside, {
              width: _unref(layoutStore).collapsed ? _unref(layoutStore).supportColumn ? '80px' : '64px' : _unref(layoutStore).supportColumn ? '280px' : '204px',
              class: "sidebar"
            }, {
              default: _withCtx(() => [_createVNode(Sidebar, {
                style: {
                  "overflow": "hidden"
                }
              })]),
              _: 1
            }, 8, ["width"]), _createElementVNode("div", _hoisted_8, [_unref(layoutStore).supportTags ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("i", {
              class: _normalizeClass(["online-icon", _unref(layoutStore).collapsed ? 'icon-expand' : 'icon-unexpand']),
              style: {
                "font-size": "16px",
                "color": "#333",
                "cursor": "pointer"
              },
              onClick: _cache[0] || (_cache[0] = () => _unref(layoutStore).toggleCollapsed())
            }, null, 2), _createVNode(TagPanel)])) : _createCommentVNode("", true), _createVNode(_component_el_scrollbar, {
              "wrap-class": "scrollbar_dropdown__wrap",
              style: _normalizeStyle(_unref(getContextStyle))
            }, {
              default: _withCtx(() => [_createVNode(_component_router_view, {
                class: "page-box",
                style: _normalizeStyle([{
                  "overflow": "hidden",
                  "margin": "16px"
                }, _unref(getRouterViewStyle)])
              }, {
                default: _withCtx(({
                  Component
                }) => [_createVNode(_Transition, {
                  name: "el-fade-in-linear",
                  appear: true
                }, {
                  default: _withCtx(() => [(_openBlock(), _createBlock(_KeepAlive, {
                    include: _unref(layoutStore).cachePages
                  }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1032, ["include"]))]),
                  _: 2
                }, 1024)]),
                _: 1
              }, 8, ["style"])]),
              _: 1
            }, 8, ["style"])])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["class", "style"]);
    };
  }
});