import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  style: {
    "position": "relative",
    "height": "100%"
  },
  class: "sidebar-bg"
};
import { useLayoutStore } from '@/store';
import SubMenu from './SubMenu.vue';
import multiColumn from './multi-column.vue';
import { useSelectMenu } from './hooks';
export default /*@__PURE__*/_defineComponent({
  __name: 'Sidebar',
  setup(__props) {
    const layoutStore = useLayoutStore();
    const {
      selectMenuById
    } = useSelectMenu();
    return (_ctx, _cache) => {
      const _component_el_menu = _resolveComponent("el-menu");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_unref(layoutStore).supportColumn ? (_openBlock(), _createBlock(multiColumn, {
        key: 0,
        menuList: _unref(layoutStore).menuList
      }, null, 8, ["menuList"])) : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["left-menu", _unref(layoutStore).collapsed ? 'collapse' : '']),
        style: {
          "height": "100%",
          "padding-top": "16px",
          "padding-bottom": "16px"
        }
      }, [_createVNode(_component_el_scrollbar, {
        "wrap-class": "scrollbar_dropdown__wrap",
        style: {
          "height": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_menu, {
          mode: "vertical",
          "active-text-color": "#ffd04b",
          "text-color": "#a4a5a7",
          "background-color": "#2d3039",
          "default-active": _unref(layoutStore).currentMenuId,
          "unique-opened": true,
          onSelect: _unref(selectMenuById),
          collapse: _unref(layoutStore).collapsed
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(layoutStore).menuList, menu => {
            return _openBlock(), _createBlock(SubMenu, {
              key: menu.menuId,
              menu: menu
            }, null, 8, ["menu"]);
          }), 128))]),
          _: 1
        }, 8, ["default-active", "onSelect", "collapse"])]),
        _: 1
      })], 2))]);
    };
  }
});