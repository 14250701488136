import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeStyle as _normalizeStyle, isRef as _isRef } from "vue";
import _imports_0 from '@/assets/img/right-icon.png';
import _imports_1 from '@/assets/img/demo-h5-qrcode.png';

import { ref, inject, computed } from 'vue';
const _hoisted_1 = {
  style: {
    "padding": "16px",
    "font-size": "14px !important",
    "background-color": "white"
  }
};
const _hoisted_2 = {
  class: "cases-content"
};
export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {
    const activeName = ref('about');
    const tabPaneList = ref([{
      title: 'PC 端在线表单',
      tableData: [{
        menuName: '在线工单示例 / 合同审批',
        flowPage: '是',
        bindFlow: '合同审批 (在线)',
        caseDesc: '一对多数据多标签展示、会签和加减签、并行网关驳回。'
      }, {
        menuName: '在线工单示例 / 报销申请',
        flowPage: '是',
        bindFlow: '报销申请 (在线)',
        caseDesc: '钉钉风格流程编辑器。'
      }, {
        menuName: '在线工单示例 / 自由跳示例',
        flowPage: '是',
        bindFlow: '自由跳演示',
        caseDesc: '在流程发布时，橙单做了静态的流程分析，因此可以极为准确高效的执行驳回和自由跳逻辑。'
      }, {
        menuName: '在线工单示例 / 文章管理',
        flowPage: '否',
        bindFlow: '文章审批',
        caseDesc: '数据列表页面可以多次发起审批流程，支持数据表字段作为下一任务审批人。'
      }, {
        menuName: '在线表单示例 / 老师管理',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '左树右表页面配置，一对一关联选择组件。'
      }, {
        menuName: '在线表单示例 / 学生管理',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '新建弹框支持「线框表单」、省市区划级联、批量删除。'
      }, {
        menuName: '在线表单示例 / 课程管理',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '支持数据表字典和全局编码字典关联，支持一对多从表数据聚合计算显示，如章节数量。'
      }, {
        menuName: '在线统计页面 / 图表展示',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '统计图表和自定义卡片展示。'
      }, {
        menuName: '在线统计页面 / 卡片展示',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '自定义卡片、API 数据源和透视表展示。'
      }, {
        menuName: '在线统计页面 / 下钻展示',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '图表组件「双击」后弹框显示下钻数据。'
      }]
    }, {
      title: 'PC 端路由表单',
      tableData: [{
        menuName: '路由工单示例 / 合同审批',
        flowPage: '是',
        bindFlow: '合同审批',
        caseDesc: '一对多数据多标签展示、会签和加减签、并行网关驳回。'
      }, {
        menuName: '路由工单示例 / 报销申请',
        flowPage: '是',
        bindFlow: '报销申请',
        caseDesc: '功能完全等同于在线表单的报销申请流程。'
      }, {
        menuName: '路由表单示例 / 班级管理',
        flowPage: '否',
        bindFlow: '创建班级审批',
        caseDesc: '左树右表、多对多级联添加。数据列表页面可以「多次发起审批流程」，列表支持进度条和从表数据聚合计算，如学生数量。'
      }, {
        menuName: '路由表单示例 / 作业管理',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '列表页面即可打开多对多关联数据列表。'
      }, {
        menuName: '路由表单示例 / 课程管理',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '支持一对多对多复杂关联、一对多的级联添加，编辑页面支持多标签，和显示权限控制。'
      }, {
        menuName: '路由表单示例 / 学生管理',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '支持部分更新。'
      }, {
        menuName: '路由表单示例 / 视频课程',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '支持主子表布局。'
      }, {
        menuName: '路由统计页面 / 学生行为统计',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '点击表格单元中的统计数字，可「下钻」打开详情列表页面。'
      }, {
        menuName: '路由统计页面 / 视频统计',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '支持图表展示。'
      }]
    }, {
      title: '移动端表单功能',
      tableData: [{
        menuName: '在线表单 / 合同审批',
        flowPage: '是',
        bindFlow: '合同审批 (在线)',
        caseDesc: '一对多数据多标签展示、会签和加减签、并行网关驳回。'
      }, {
        menuName: '在线表单 / 课程管理',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '支持数据表字典和全局编码字典关联，支持一对多从表数据级联添加和编辑。'
      }, {
        menuName: '在线表单 / 报表页面',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '统计图表和自定义卡片展示。'
      }, {
        menuName: '路由表单 / 课程管理',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '支持一对多的级联添加。'
      }, {
        menuName: '路由表单 / 班级管理',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '支持多对多的级联添加。'
      }, {
        menuName: '路由表单 / 合同审批',
        flowPage: '是',
        bindFlow: '合同审批',
        caseDesc: '一对多数据多标签展示、会签和加减签、并行网关驳回。'
      }, {
        menuName: '路由表单 / 视频统计',
        flowPage: '否',
        bindFlow: '无',
        caseDesc: '支持图表展示。'
      }]
    }]);
    const mainContextHeight = inject('mainContextHeight', ref(200));
    const getContentStyle = computed(() => {
      return [{
        'min-height': mainContextHeight.value - 221 - 24 - 16 + 'px'
      }];
    });
    return (_ctx, _cache) => {
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_tabs = _resolveComponent("el-tabs");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[8] || (_cache[8] = _createElementVNode("div", {
        class: "title",
        style: {
          "margin-top": "-16px",
          "margin-right": "-16px",
          "margin-left": "-16px"
        }
      }, [_createElementVNode("p", null, [_createTextVNode("橙单，最纯粹的代码生成工具，这一切"), _createElementVNode("span", {
        style: {
          "color": "#f70"
        }
      }, "「橙单」"), _createTextVNode("为您生成。")]), _createElementVNode("img", {
        class: "title-img",
        src: _imports_0,
        alt: ""
      })], -1)), _createVNode(_component_el_row, {
        type: "flex",
        style: {
          "padding": "24px 11px 0"
        },
        class: "about"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tabs, {
          modelValue: _unref(activeName),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(activeName) ? activeName.value = $event : null),
          type: "card",
          style: {
            "width": "100%"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
            label: "关于橙单",
            name: "about"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_row, null, {
              default: _withCtx(() => [_createVNode(_component_el_col, {
                span: 12,
                class: "item-block",
                style: {
                  "border-right": "0",
                  "border-bottom": "0"
                }
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("span", {
                  class: "item-title"
                }, "基础架构", -1), _createElementVNode("ul", {
                  class: "item-list"
                }, [_createElementVNode("li", null, " 前后端全部主流技术栈，Spring Boot / Spring Cloud / Element Plus / Vant / Uniapp。 "), _createElementVNode("li", null, " 基础组件包含，工作流 / 在线表单 / 统计表单 / 移动端 / 多租户，全部深度自研，完整可控。 "), _createElementVNode("li", null, " 弹性架构，服务组件可插拔，第三方框架快速集成，流程 / 表单 / 报表等服务支持多应用接入。 "), _createElementVNode("li", null, " 支持多种数据库的混合集成，MySQL / Oracle / PostgreSQL / 达梦 / 人大金仓 / OpenGauss。 ")], -1)])),
                _: 1
              }), _createVNode(_component_el_col, {
                span: 12,
                class: "item-block",
                style: {
                  "border-bottom": "0"
                }
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("span", {
                  class: "item-title"
                }, "产品质量", -1), _createElementVNode("ul", {
                  class: "item-list"
                }, [_createElementVNode("li", null, "全部源码通过 SonarQube 最严格的扫描「0」警告，该标准近乎业内唯一。"), _createElementVNode("li", null, "前端样式提供统一的多分辨率设计标准，精致且规范。"), _createElementVNode("li", null, " 橙单在代码数量、质量与核心功能之间进行了良好的平衡，BUG 很少，对二次开发极为友好。 "), _createElementVNode("li", null, "无技术债，核心团队会定期主动优化产品中可能存在的开发体验问题。")], -1)])),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_row, null, {
              default: _withCtx(() => [_createVNode(_component_el_col, {
                span: 12,
                class: "item-block",
                style: {
                  "border-right": "0"
                }
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("span", {
                  class: "item-title"
                }, "商业授权", -1), _createElementVNode("ul", {
                  class: "item-list"
                }, [_createElementVNode("li", null, " 全部源码交付，无代码混淆，永久免费升级，无项目数量限制，无后续费用，永久性授权。 "), _createElementVNode("li", null, "最高性价比的同类产品，我们在定价之前，曾寻遍全网。"), _createElementVNode("li", null, "宽松的商业授权，无任何附加条件，企业所想即是橙单所为。"), _createElementVNode("li", null, " 支持绝对「免费」的代码生成服务，生成后源码全部交付，无任何橙单痕迹和包依赖。 ")], -1)])),
                _: 1
              }), _createVNode(_component_el_col, {
                span: 12,
                class: "item-block"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("span", {
                  class: "item-title"
                }, "技术支持", -1), _createElementVNode("ul", {
                  class: "item-list"
                }, [_createElementVNode("li", null, "橙单，时刻为您承担，绝对不是一句口号，我们开车等灯时都会为用户答疑。"), _createElementVNode("li", null, " 提供技术支持微信群，消息回复及时，问题解答高效耐心，所有用户均可对我们的服务进行监控。 "), _createElementVNode("li", null, " 文档完整详尽、结构清晰、图文并茂、美观且具备良好的阅读体验，我们根据用户反馈实时更新。 "), _createElementVNode("li", null, " 定期升级迭代，用户反馈的问题会及时修复，新功能需求将在内部评估后列入开发计划。 ")], -1)])),
                _: 1
              })]),
              _: 1
            }), _cache[5] || (_cache[5] = _createElementVNode("div", {
              class: "item-block",
              style: {
                "display": "flex",
                "justify-content": "space-between",
                "align-items": "center",
                "padding-right": "35px",
                "margin-top": "16px"
              }
            }, [_createElementVNode("div", null, [_createElementVNode("span", {
              class: "item-title"
            }, "我们的资源"), _createElementVNode("ul", {
              class: "item-list"
            }, [_createElementVNode("li", null, [_createTextVNode(" 免费代码生成 "), _createElementVNode("a", {
              href: "https://code.orangeforms.com",
              target: "_blank"
            }, "https://code.orangeforms.com")]), _createElementVNode("li", null, [_createTextVNode(" 线上技术文档 "), _createElementVNode("a", {
              href: "https://www.orangeforms.com",
              target: "_blank"
            }, "https://www.orangeforms.com")]), _createElementVNode("li", null, [_createTextVNode(" 租户管理演示 "), _createElementVNode("a", {
              href: "http://tenantadmin.orangeforms.com",
              target: "_blank"
            }, "http://tenantadmin.orangeforms.com")]), _createElementVNode("li", null, [_createTextVNode(" 租户运营演示 "), _createElementVNode("a", {
              href: "http://tenant.orangeforms.com",
              target: "_blank"
            }, "http://tenant.orangeforms.com")])])]), _createElementVNode("div", {
              style: {
                "display": "inherit"
              }
            }, [_createElementVNode("div", {
              class: "qrcode"
            }, [_createElementVNode("img", {
              class: "title-img",
              src: _imports_1,
              alt: "",
              style: {
                "height": "128px"
              }
            }), _createElementVNode("span", {
              class: "icon-name"
            }, "移动端演示")])])], -1))]),
            _: 1
          }), _createVNode(_component_el_tab_pane, {
            label: "示例说明",
            name: "cases",
            lazy: true
          }, {
            default: _withCtx(() => [_cache[6] || (_cache[6] = _createElementVNode("p", {
              style: {
                "margin-bottom": "16px"
              }
            }, [_createElementVNode("strong", null, "在线表单，"), _createTextVNode(" 通过在线表单编辑器创建，配置并绑定菜单后即可生效，无代码修改，适用于相对简单的表单页面。 "), _createElementVNode("strong", null, "路由表单，"), _createTextVNode(" 通过橙单生成器创建，可生成前后端代码，需编译后部署，适用于二次开发的复杂表单或业务逻辑。 ")], -1)), _createVNode(_component_el_tabs, {
              "tab-position": "left",
              class: "cases item-block",
              style: _normalizeStyle([{
                "display": "flex",
                "padding": "0"
              }, _unref(getContentStyle)])
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tabPaneList), (tabPane, index) => {
                return _openBlock(), _createBlock(_component_el_tab_pane, {
                  key: index
                }, {
                  label: _withCtx(() => [_createElementVNode("li", null, _toDisplayString(tabPane.title), 1)]),
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
                    type: "flex"
                  }, {
                    default: _withCtx(() => [_createVNode(_component_el_col, {
                      span: 24
                    }, {
                      default: _withCtx(() => [_createVNode(_component_el_table, {
                        class: "content-table",
                        data: tabPane.tableData,
                        border: ""
                      }, {
                        default: _withCtx(() => [_createVNode(_component_el_table_column, {
                          prop: "menuName",
                          label: "菜单名称",
                          width: "250"
                        }), _createVNode(_component_el_table_column, {
                          prop: "flowPage",
                          label: "流程页面",
                          width: "150"
                        }), _createVNode(_component_el_table_column, {
                          prop: "bindFlow",
                          label: "绑定流程",
                          width: "150"
                        }), _createVNode(_component_el_table_column, {
                          prop: "caseDesc",
                          label: "示例说明"
                        })]),
                        _: 2
                      }, 1032, ["data"])]),
                      _: 2
                    }, 1024)]),
                    _: 2
                  }, 1024)])]),
                  _: 2
                }, 1024);
              }), 128))]),
              _: 1
            }, 8, ["style"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue"]), _cache[7] || (_cache[7] = _createElementVNode("div", {
          class: "qq",
          style: {
            "position": "absolute",
            "top": "34px",
            "right": "25px"
          }
        }, [_createElementVNode("span", null, "技术支持 QQ 群 664800781")], -1))]),
        _: 1
      })]);
    };
  }
});