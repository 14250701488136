import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "menu-wrapper"
};
import { useLayoutStore } from '@/store';
import OrangeIcon from '@/components/icons/index.vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'SubMenu',
  props: {
    menu: {},
    isChild: {
      type: Boolean
    }
  },
  setup(__props) {
    const store = useLayoutStore();
    const props = __props;
    // const getIconStyle = (isShow: boolean) => {
    //   if (isShow && props.isChild) {
    //     return [{ 'margin-left': '13px' }];
    //   }
    // };
    const getTextStyle = isShow => {
      if (isShow && props.isChild) {
        return [{
          'padding-left': '13px'
        }];
      }
    };
    return (_ctx, _cache) => {
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_sub_menu = _resolveComponent("sub-menu", true);
      const _component_el_sub_menu = _resolveComponent("el-sub-menu");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.menu.children == null || _ctx.menu.children.length == 0 ? (_openBlock(), _createBlock(_component_el_menu_item, {
        key: 0,
        ref: "item",
        index: _ctx.menu.menuId
      }, {
        title: _withCtx(() => [_ctx.menu.icon ? (_openBlock(), _createBlock(OrangeIcon, {
          key: 0,
          icon: _ctx.menu.icon,
          class: "menu-icon"
        }, null, 8, ["icon"])) : _createCommentVNode("", true), _createElementVNode("span", {
          style: _normalizeStyle(getTextStyle(!_ctx.menu.icon))
        }, _toDisplayString(_ctx.menu.menuName), 5)]),
        default: _withCtx(() => [_ctx.menu.icon && !_ctx.isChild && _unref(store).collapsed ? (_openBlock(), _createBlock(OrangeIcon, {
          key: 0,
          icon: _ctx.menu.icon,
          class: "menu-icon"
        }, null, 8, ["icon"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["index"])) : (_openBlock(), _createBlock(_component_el_sub_menu, {
        key: 1,
        index: _ctx.menu.menuId
      }, {
        title: _withCtx(() => [_ctx.menu.icon ? (_openBlock(), _createBlock(OrangeIcon, {
          key: 0,
          icon: _ctx.menu.icon,
          class: "menu-icon"
        }, null, 8, ["icon"])) : _createCommentVNode("", true), _withDirectives(_createElementVNode("span", {
          style: _normalizeStyle(getTextStyle(!_ctx.menu.icon))
        }, _toDisplayString(_ctx.menu.menuName), 5), [[_vShow, !_unref(store).collapsed || _ctx.isChild]])]),
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.children, child => {
          return _openBlock(), _createBlock(_component_sub_menu, {
            key: child.menuId,
            class: "nest-menu",
            menu: child,
            isChild: true
          }, null, 8, ["menu"]);
        }), 128))]),
        _: 1
      }, 8, ["index"]))]);
    };
  }
});